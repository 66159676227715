<template>
    <main class="animated fadeInUpBig">
        <!-- slider Area Start-->
        <div class="slider-area ">
            <!-- Mobile Menu -->
            <div class="single-slider slider-height2 d-flex align-items-center"
                 :style="{'background-image': 'url(' + require('../assets/img/hero/404_Image.jpg') + ')'}">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap text-center">
                                <h2>404</h2>
                                <h2>Ops! Parece que se enganou.</h2>
                                <router-link to="/">Voltar | Home</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- slider Area End-->
    </main>
</template>

<script>
    export default {
        name: "NotFoundPage"
    }
</script>

<style scoped>

</style>
